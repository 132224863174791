<template>
	<div class="page">
		<div style="width: 1280px;margin: 30px auto;">
			<el-carousel :interval="4000" height="466.6px">
				<el-carousel-item v-for="(item,index) in carouselArr" :key="index" >
					<el-image style="width:100%" :src="item" fit="fill"></el-image>
					<!-- <img :src="item" alt="" style="width: 100%;"> -->
				</el-carousel-item>
			</el-carousel>
		</div>
		<newestNotic ref="newestNotic" style="margin-top: 50px;"></newestNotic>
		<testPaperCenter ref="testPaperCenter" style="margin-top: 50px;"></testPaperCenter>
		<!-- <loginDialog :loginDialogShow="loginDialogShow" @closeDialog="closeDialog" @loginSuccess="loginSuccess"></loginDialog> -->
	</div>
</template>

<script>
	import loginDialog from "./components/loginDialog.vue";
	import newestNotic from "./components/newestNotic.vue";
	import testPaperCenter from "./components/testPaperCenter.vue";
	export default {
		components: {
			loginDialog,
			newestNotic,
			testPaperCenter
		},
		data() {
			return {
				carouselArr: [
					// require("@/assets/banner1.png"),
					// require("@/assets/banner2.png"),
          // "http://www.wancode.net:8585/wancode/back/image/read/8928",
          "http://www.wancode.net:8585/wancode/back/image/read/8929",
					// require("http://www.wancode.net:8585/wancode/back/image/read/8928"),
					// require("http://www.wancode.net:8585/wancode/back/image/read/8929"),
				],
				loginDialogShow: false,
				isHidden: false,
				dafaultContent: ""
			};
		},
		mounted() {
			// console.log(localStorage.getItem("token"))
			if (localStorage.getItem("token")) {
				this.loginDialogShow = false
			} else {
				this.loginDialogShow = true
			}
		},
		methods: {
			closeDialog() {
				this.isHidden = true;
				setTimeout(() => {
					this.loginDialogShow = false
				})
			},
			loginSuccess() {
				this.$refs.newestNotic.getInfo()
				this.$refs.testPaperCenter.getInfo()
			},
		}
	};
</script>

<style scoped></style>
