<template>
	<div>
		<el-dialog :visible.sync="loginDialog" :show-close="false" class="login-container" width="500px">
			<particle-effect-button :hidden="isHidden" color="LightPink" :duration="800" type="circle"
				draw-style="fill">
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
					label-position="left">
					<div class="title-container">
						<h3 class="title">登录</h3>
					</div>

					<el-form-item prop="username">
						<div class="svg-input" style="display: flex;">
							<i class="el-icon-user svg-container"></i>
							<el-input ref="username" v-model="loginForm.username" placeholder="请输入用户名" name="username"
								type="text" tabindex="1" auto-complete="on"></el-input>
						</div>
					</el-form-item>

					<el-form-item prop="password">
						<div style="display: flex;">
							<i class="el-icon-lock svg-container"></i>

							<el-input :key="passwordType" ref="password" v-model="loginForm.password"
								:type="passwordType" placeholder="请输入密码" name="password" tabindex="2" auto-complete="on"
								@keyup.enter.native="handleLogin">
							</el-input>
							<i :class="passwordType === 'password' ? 'el-icon-lock svg-container' : 'el-icon-unlock svg-container'"
								@click="showPwd"></i>
						</div>

					</el-form-item>

					<el-button :loading="loading" type="primary" style="width: 424px;margin-bottom: 30px;"
						@click.native.prevent="handleLogin">登录</el-button>
				</el-form>
			</particle-effect-button>
		</el-dialog>

	</div>
</template>

<script>
	import ParticleEffectButton from 'vue-particle-effect-button'
	import {
		submitPaperAnswer
	} from "@/api/index.js"

	import {
		login
	} from '@/api/index.js'
	export default {
		props: ["loginDialogShow"],
		components: {
			ParticleEffectButton
		},
		watch: {
			loginDialogShow: {
				handler: function(n, o) {
					this.loginDialog = this.loginDialogShow;
				}
			},
			loginDialog: {
				handler: function(n, o) {
					if (this.loginDialog) {} else {
						this.$emit("closeDialog");
					}
				}
			}
		},

		data() {
			const validateUsername = (rule, value, callback) => {
				if (value.length < 3) {
					callback(new Error("请输入正确的用户名"))
				} else {
					callback();
				}
			};
			const validatePassword = (rule, value, callback) => {
				if (value.length < 3) {
					callback(new Error("请输入正确的密码"))
				} else {
					callback();
				}
			};

			return {
				loginDialog: false,
				isHidden: false,
				loginForm: {
					username: "",
					password: ""
				},
				loginRules: {
					username: [{
						required: true,
						trigger: "blur",
						validator: validateUsername
					}],
					password: [{
						required: true,
						trigger: "blur",
						validator: validatePassword
					}]
				},
				loading: false,
				passwordType: "password",
				// redirect: undefined
			};
		},
		methods: {
			showPwd() {
				if (this.passwordType === "password") {
					this.passwordType = ""
				} else {
					this.passwordType = "password"
				}
				this.$nextTick(() => {
					this.$refs.password.focus()
				})
			},
			handleLogin() {
				// this.$emit("loginSuccess")

				this.$refs.loginForm.validate(valid => {
					if (valid) {
						let param = {
							loginAccount: this.loginForm.username,
							password: this.loginForm.password
						}
						login(param).then(res => {
							if (res.code == "0000") {
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("username", this.loginForm.username);
								this.loading = true;
								this.isHidden = true;
								// console.log(localStorage.getItem("underSend"))
								if (localStorage.getItem("underSend")) {
									let data = JSON.parse(localStorage.getItem("underSend"));
									submitPaperAnswer(param).then(res => {
										// console.log(res)
										if (res.code == "0000") {
											localStorage.setItem("underSend", "")
										}
									})
								}
								setTimeout(() => {
									this.loginDialog = false;
									this.$message.success("登录成功")
									this.$emit("loginSuccess")
								}, 1500)
							}
						})
					}
				})

			}
		}
	};
</script>


<style lang="scss" scoped>
	$bg:#283443;
	$light_gray:#FFF;
	$cursor:#000;
	$bg1:#2d3a4b;
	$light_gray1:#eee;
	$dark_gray:#889aa4;


	@supports (-webkit-mask:none) and (not (cater-color:$cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/deep/.el-form-item {
		border: 1px solid rgba(255, 255, 255, 0.1);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		color: #454545;
	}

	.login-form {
		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: #000000;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.svg-container {
			// padding: 6px 5px 6px 15px;
			color: $dark_gray;
			font-size: 18px;
			line-height: 47px;
			margin-left: 10px;
			// vertical-align: middle;
			width: 47px;
			// display: inline-block;
		}


		/deep/.el-input {
			display: inline-block;
			height: 47px;
			width: 300px;
			margin-left: 10px;

			input {
				background: transparent;
				border: none;
				-webkit-appearance: none;
				border-radius: 10px;
				// padding: 12px 5px 12px 15px;
				color: #000000;
				height: 47px;
				caret-color: $cursor;

				// &:-webkit-autofill {
				// 	box-shadow: 0 0 0px 1000px #cccccc inset !important;
				// 	-webkit-text-fill-color: $cursor !important;
				// }
			}
		}


		// height: 320px;
		// 	z-index: 999;
		// 	position: absolute;
		// 	width: 520px;
		// 	max-width: 100%;
		// 	top: 50%;
		// 	margin-top: -160px;
		// 	padding-top: 20px;
		// 	overflow: hidden;
		// 	transition: 0.5s;

	}


	/deep/.el-dialog__header {
		display: none !important
	}

	/deep/.el-dialog__body {
		// background-image: url(../../../assets/carousel/2.png);
		// background-color: rgba(255, 255, 255, 1);
		// background-color: #cccccc;
	}

	/deep/.el-dialog__body::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		// background-image: url(./assets/carousel/2.png);
		
		opacity: 0.3;
	}
</style>
