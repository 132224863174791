<template>
  <div class="testPaperCenterSty" v-loading="loading">
    <div class="bookmark">

    </div>
    <div style="font-size: 30px;line-height: 30px; text-align: center;padding-top: 50px;margin-bottom: -30px">
      <h2>近30天最多人在学</h2>
    </div>
    <div class="contentSty">
      <el-card class="box-card" shadow="hover" v-for="(item,index) in allTestPaper" :key="item.id"
               @click.native="jumpToDetails(item.id)">
        <p class="title">{{ item.name }}</p>
        <p class="content" style="margin-top: 5px">学科：{{ item.examSubject.type | formatType }}</p>
        <p class="content" style="margin-top: 5px">试卷编号：NO.{{ item.id }}</p>
        <p class="content" style="margin-top: 5px">题目数量：{{ item.questionCount }}</p>
        <p class="content" style="margin-top: 5px">试卷总分：{{ item.score }}</p>
        <p class="content" style="margin-top: 5px">建议时长：{{ item.suggestTime }}分钟</p>
        <p class="content" style="margin-top: 5px">发布时间：{{ item.createTime }}</p>
      </el-card>
    </div>
    <div style="text-align: center;padding-bottom: 40px;font-size: 18px;color: #808080;">
      <router-link to="/testPaperCenter" style="text-decoration: none;">》》》》更多试卷真题、模拟题、练习题《《《《</router-link>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        center
        :before-close="handleClose">
      <h3 style="text-align: center;font-size:26px;">{{ content }}</h3>
      <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="text-align: center;font-size:20px;" @click="toSelfVIP()"
                           round>升级会员，继续练习</el-button>
            	<div style="padding-bottom: 20px"></div>
<!--                <span style="color: red" v-show="dialogVisible2">注：开通SVIP，全站试卷均可作答</span>-->
			</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  homePaper,
  checkCountByAnswerPaperAndDay
} from '@/api/index.js'

export default {
  filters: {
    formatType(type) {
      switch (type) {
        case 0:
          return "Scratch"
          break;
        case 1:
          return "C/C++"
          break;
        case 2:
          return "Python"
          break;
        case 3:
          return "NOIP"
          break;
        case 4:
          return "机器人技术"
          break;
        case 5:
          return "科技素养/计算思维"
          break;
        case 6:
          return "无人机技术"
          break;
        default:
          return "其他"
          break;
      }
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogVisible2: false,
      content: "此作品未开通权限，不可操作！",
      allTestPaper: [{
        examSubject: {
          type: ""
        }
      }]
    }
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
    },
    toSelfVIP() {
      this.dialogVisible = false;
      this.$router.push({path: "/selfVIP"});
    },
    getInfo() {
      this.loading = true;
      homePaper().then(res => {
        if (res.code === "0000") {
          if (res.data.length <= 9) {
            this.allTestPaper = res.data
          } else {
            this.allTestPaper = res.data.slice(0, 9)
          }
          this.loading = false;
        }
      })
    },
    jumpToDetails(id) {
      checkCountByAnswerPaperAndDay(id).then(res => {
        if (res.message === "此作品未开通权限，用户不可操作！") {
          this.content = "此作品未开通权限不可操作！";
          this.dialogVisible = true
        } else if (res.message === "答题次数达到每日答题上限！可升级会员!") {
          this.content = "您好，今日答题次数已达到上限！";
          this.dialogVisible = true
        } else if (res.message === "此作品未开通权限，用户不可操作") {
          this.content = "此作品未开通权限不可操作！";
          this.dialogVisible = true
          this.dialogVisible2 = true
        } else {
          if (res.code === "0000") {
            let routeData = this.$router.resolve({
              path: "/testPaperDetails?id=" + id
            });
            window.open(routeData.href, '_blank');
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        }
      })


    },
    toPaperCenter() {
      this.$router.push({path: "testPaperCenter"})
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>

.testPaperCenterSty {
  width: 1280px;
  // max-height: 500px;
  min-height: 300px;
  background-color: #FFFFFF;
  margin: 50px auto;
  box-shadow: 10px 10px 5px #cccccc;
  position: relative;
}

.bookmark {
  position: absolute;
  top: -15px;
  right: -30px;
  width: 200px;
}


.bookmark:before {
  /*做一个书签效果*/
  position: absolute;
  /*必须*/
  // top: 250px;
  right: 20px;
  z-index: 1;
  height: 0;
  padding-left: 10px;
  font-weight: bold;
  line-height: 0;
  color: #000;
  border: 15px solid #67C23A;
  border-left-color: transparent;
  /*右边框透明，变成空缺的角*/
  content: '试卷中心';
  box-shadow: 0 5px 5px -5px #000;
}

.bookmark:after {
  /*书签的夹角*/
  content: '';
  position: absolute;
  top: 30px;
  right: 20px;
  border: 4px solid #006400;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.contentSty {
  padding: 50px 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  color: #19d3ea;
  font-size: 18px;
  cursor: pointer; /*鼠标悬停变小手*/
}

.box-card {
  width: 390px;
  text-align: left;
  margin-top: 20px;

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
  }

  .content {
    font-size: 14px;
    color: #808080;
  }
}
</style>
