<template>
  <div class="newestNoticSty" v-loading="loading">
    <div class="bookmark">

    </div>

    <div style="height: 410px;">
      <div class="newestNoticContent">
        <div class="downDiv showBlock">
          <div style="padding: 30px 5px;">
            <p class="name">用户数量</p>
            <div class="middleDiv"></div>
            <p><span class="number">{{ allInfo.userNumber| playvolume }}</span><span class="company">人</span></p>
            <p class="details">共{{ allInfo.userNumber }}位同学在参与了编程学习</p>
          </div>
        </div>
        <div class="upDiv showBlock">
          <div style="padding: 30px 5px;">
            <p class="name">题目数量</p>
            <div class="middleDiv"></div>
            <p><span class="number">{{ allInfo.questionNumber| playvolume }}</span><span class="company">道</span></p>
            <p class="details">目前共收录有{{ allInfo.questionNumber }}道题目</p>
          </div>
        </div>
        <div class="downDiv showBlock">
          <div style="padding: 30px 20px;">
            <p class="name">答题次数</p>
            <div class="middleDiv"></div>
            <p><span class="number">{{ allInfo.answerNumber| playvolume }}</span><span class="company">次</span></p>
            <p class="details">总答题次数为{{ allInfo.answerNumber }}次</p>
          </div>
        </div>
        <div class="upDiv showBlock">
          <div style="padding: 30px 5px;">
            <p class="name">编写代码</p>
            <div class="middleDiv"></div>
            <p><span class="number">{{ allInfo.writeCodeNumber| playvolume }}</span><span class="company">行</span></p>
            <p class="details">共编写了{{ allInfo.writeCodeNumber }}行代码</p>
          </div>
        </div>
        <div class="downDiv showBlock">
          <div style="padding: 30px 5px;">
            <p class="name">评判练习</p>
            <div class="middleDiv"></div>
            <p><span class="number">{{ allInfo.judgeNumber| playvolume }}</span><span class="company">次</span></p>
            <p class="details">共审批了{{ allInfo.judgeNumber }}张试题</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  homePageInfo
} from '@/api/index.js'

export default {
  data() {
    return {
      allInfo: [],
      loading: false
    }
  },
  filters: {
    //取截单元,单位
    playvolume: function (arg) {
      if (arg.toString().length >= 13) {
        // return arg/1000000000000+"万亿"
        const volume = arg / 1000000000000
        const realVal = parseFloat(volume).toFixed(1);
        return realVal + "万亿"
      } else if (arg.toString().length >= 9) {
        const volume = arg / 100000000
        const realVal = parseFloat(volume).toFixed(1);
        return realVal + "亿"
      } else if (arg.toString().length > 4) {
        const volume = arg / 10000
        const realVal = parseFloat(volume).toFixed(0);
        return realVal + "万"
      }else{
        return arg
      }
    }
  },
  methods: {
    getInfo() {
      this.loading = true
      homePageInfo().then(res => {
        if (res.code == "0000") {
          // console.log(res)
          this.allInfo = res.data
          this.loading = false
        }
      })
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.showBlock {
  height: 200px;
  width: 200px;

  background-color: #FFFFFF;
  box-shadow: 5px 5px 5px #808080;

  .name {
    font-size: 24px;
    font-weight: 900;
  }

  .middleDiv {
    width: 100%;
    height: 5px;
    background-color: aquamarine;
  }

  .number {
    font-size: 48px;
    font-weight: 800;
  }

  .company {
    color: #808080;
    font-size: 28px;
  }

  .details {
    font-size: 18px;
    color: #808080;
  }


}

.downDiv {
  -webkit-animation: bounce-down 3s linear infinite;
  animation: bounce-down 3s linear infinite;
  margin-top: 160px;
}

.upDiv {
  -webkit-animation: bounce-up 3s linear infinite;
  animation: bounce-up 3s linear infinite;
  margin-top: 50px;
}

@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-10px);
  }

  50%,
  100% {
    -webkit-transform: translateY(0);
  }

  75% {
    -webkit-transform: translateY(10px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }

  50%,
  100% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(10px);
  }
}

@-webkit-keyframes bounce-up {
  25% {
    -webkit-transform: translateY(10px);
  }

  50%,
  100% {
    -webkit-transform: translateY(0);
  }

  75% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce-up {
  25% {
    transform: translateY(10px);
  }

  50%,
  100% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-10px);
  }
}


.newestNoticSty {
  width: 1280px;
  height: 410px;
  margin: 50px auto;
  position: relative;

  .newestNoticContent {
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }


}

// .bookmark {
// 	position: absolute;
// 	top: -15px;
// 	left: -30px;
// 	width: 200px;
// }


// .bookmark:before {
// 	/*做一个书签效果*/
// 	position: absolute;
// 	/*必须*/
// 	// top: 250px;
// 	left: 20px;
// 	z-index: 1;
// 	height: 0;
// 	padding-right: 10px;
// 	font-weight: bold;
// 	line-height: 0;
// 	color: #000;
// 	border: 15px solid #ee7600;
// 	border-right-color: transparent;
// 	/*右边框透明，变成空缺的角*/
// 	content: '最新公告';
// 	box-shadow: 0 5px 5px -5px #000;
// }

// .bookmark:after {
// 	/*书签的夹角*/
// 	content: '';
// 	position: absolute;
// 	top: 30px;
// 	left: 20px;
// 	border: 4px solid #89540c;
// 	border-left-color: transparent;
// 	border-bottom-color: transparent;
// }

.box-card {
  text-align: left;
  margin-top: 10px;

  /deep/ .el-card__body {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    .newsContent {
      height: 100px;
      margin-left: 10px;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 18px;
        font-weight: 800;
        line-height: 30px;
        height: 35px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .time {
        font-size: 14px;
        color: #808080;
        line-height: 25px;
        height: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .content {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        height: 40px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

}
</style>
